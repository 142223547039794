import "./App.css";
import { useState, useEffect, useCallback } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// import AddNewInsured from "./components/quote/AddNewInsured";
import Breadcrumbs from "./components/Breadcrumbs";
import Navigation from "./components/Navigation";
import EditProgram from "./components/settings/programs/EditProgram";
import Home from "./components/Dashboard";
import Task from "./components/task/Task";
import Tasks from "./components/task/Tasks";
import Programs from "./components/Programs";
import Quote from "./components/quote/Quote";
import Stepper from "./components/stepper/Stepper";
import Settings from "./components/settings/Settings";
import Loader from "./components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_PATH;

export default function App() {
  const client = new QueryClient();
  const {
    error,
    isLoading,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const [accessToken, setAccessToken] = useState("");

  const getAccessToken = useCallback(async () => {
    const domain = process.env.REACT_APP_AUTH_DOMAIN;
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        },
      });
      setAccessToken(token);
      axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      });
    } catch (e) {
      console.log(e.message);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!isAuthenticated) {
      getAccessToken();
    }
  }, [isAuthenticated, getAccessToken]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen w-full bg-white dark:bg-zinc-950">
        <Loader />
      </div>
    );
  if (error) return <div>{error.message}</div>;

  const formattedUrl = (url) => url.toLowerCase().replace(/\s+/g, "-");

  const toastTheme = localStorage.getItem("theme") || "light";

  console.log(accessToken);

  return (
    <QueryClientProvider client={client}>
      <ToastContainer theme={toastTheme} className="z-[9999]" />
      <Navigation format={formattedUrl} />
      <div className="min-h-screen bg-white dark:bg-zinc-950 lg:ml-64">
        <Breadcrumbs />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/settings"
            element={<Navigate replace to="/settings/general" />}
          />
          <Route path="/settings/:id" element={<Settings />} />
          <Route
            path="/settings/programs/:id/:title?"
            element={<EditProgram />}
          />
          <Route path="/task" element={<Tasks format={formattedUrl} />} />
          <Route path="/task/:id" element={<Task format={formattedUrl} />} />
          <Route path="/quote" element={<Quote />} />
          <Route
            path="/stepper"
            element={<Stepper accessToken={accessToken} />}
          />
          <Route path="/quote/programs" element={<Programs />} />
          <Route
            path="/quote/:stepId"
            element={<Stepper accessToken={accessToken} />}
          />
          <Route path="/skeletor" element={<Loader />} />
        </Routes>
      </div>
    </QueryClientProvider>
  );
}
